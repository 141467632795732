import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"
import "../../assets/camera.sass"
import Carousel from 'react-bootstrap/Carousel'

const Camerascoping = () => (
  <div className="camera-scoping-component">
    <SEO title="Camera" />
    <div className="service-header">
      <h1 className="camera-scoping-header">SEWER CAMERA INSPECTION</h1>
      <h3>Camera Scooping</h3>
    </div>

    <div className="camera-statements camera-first-statement camera-statement-highlight">
      <p>
        If you are in the market to purchase a new home, be sure to hire a sewer camera inspection expert before you close! Camera scoping can help diagnose any issues within the sewer pipes of the home and help you save money.
      </p>
    </div>

    <div className="video-container desktop-image-video">
      <video playsInline autoPlay loop muted>
        <source src={"https://firebasestorage.googleapis.com/v0/b/tonysdrain-ef299.appspot.com/o/video-camera-edit.mp4?alt=media&token=84d5a21b-d933-42e2-8249-4b88a02fa61f"} type="video/mp4" />
      </video>
    </div>

    <div className="desktop-statements">
      <p>
        Homebuyers are highly encouraged to hire a sewer and plumbing camera inspection specialist as part of the home inspection. 
        This will bring you peace of mind and could help avoid surprises and costly sewer and plumbing repairs in the future.
      </p>
    </div>


    <div className="sewer-scoping-images-statement-wrapper">    
      <div className="sewer-scoping-statement camera-statements desktop-statement desktop-statement-view">
        <p>
        <span className="bold-text">Sewer Camera scoping</span>, also known as <span className="bold-text">Sewer Camera Inspection</span> and <span className="bold-text">Sewer Line Video Inspection </span> 
        consists of running a camera inside the sewer line. This inspection will reveal the current condition of the sewer and plumbing lines and find potential issues.
        </p>
      </div>

      <Carousel>
        <Carousel.Item interval={2500}>
          <div className="scoping-images">
            <Image filename="tonys-camera-backshot.jpg"/>
          </div>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <div className="scoping-images">
            <Image filename="tonys-camera-knee.jpg"/>
          </div>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <div className="scoping-images">
            <Image filename="roof-carema.jpg"/>
          </div>
        </Carousel.Item>
      </Carousel>

    </div>

    <div className="camera-statements camera-statement-highlight">
      <p>
        During a sewer camera scoping service, our highly-trained and experienced technician runs a camera inside the sewer line. 
        This camera is equipped with a push rod and allows the technician to maneuver through all the turns and twists in the plumbing line. 
        The camera can examine the conditions, diameter, and material of the pipe.
      </p>
    </div>
    
    <div className="camera-statements">
      <p>
        A live video feed is transmitted in real-time, where the specialist can assess the conditions of the sewer pipe immediately along with any problems, 
        such as <span className="bold-text">root intrusion</span>, <span className="bold-text">build-up</span>, or any <span className="bold-text">damages in the pipe</span>. The video can be saved for any further plumbing needs.
      </p>

    </div>

  </div>
)

export default Camerascoping
